<template>
	<div>
		<div class="row">
			<div class="col d-flex justify-content-center">
				<div
					class="mt-3 h2">
					{{ translate('purchase_confirmation') }}
				</div>
			</div>
		</div>
		<b-alert
			v-if="hasPurchaseErrors"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in purchaseErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
		<div
			:class="['xs'].includes(windowWidth) ? 'mt-2' : 'mt-3'"
			class="row mb-5">
			<div class="col-12 col-md-8">
				<div class="row">
					<div class="col-12">
						<flow-info-group>
							<flow-info-section
								v-if="!hidePayment"
								:compact="isCompact('RenewalPayment')"
								:init-edit="isEditing('RenewalPayment')"
								:hide-controls="isEditing('RenewalPayment') || loadingInitialInformation"
								:title="`${translate('payment_method')}`"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="isEditing('RenewalPayment')"
								@toggled="editStep('RenewalPayment')">
								<payment-overview
									v-if="!isEditing('RenewalPayment')"
									:loading-override="loadingInitialInformation"
									:can-split-with-wallet="canSplitWithWallet"
									:cart-total="cartNumericTotal"
									@walletSplit="enableWalletSplitAndEdit"
									@changePaymentMethodSelected="changePaymentMethodSelected"
									@noData="editStep('RenewalPayment')" />
								<payment-edit
									v-if="isEditing('RenewalPayment')"
									ref="paymentEdit"
									:payment-method-conditions="paymentMethodConditions"
									:cart-id="cartId"
									:can-split-with-wallet="canSplitWithWallet"
									:wallet-split.sync="walletSplit"
									:cart-total="cartNumericTotal"
									:system-loading="systemLoading"
									:can-split-payment="canSplitPayment"
									:membership="membership"
									@splitPayment="isSplitPayment = $event"
									@cancel="handleCancelledStep('RenewalPayment')"
									@cartValidationError="redirectToStore"
									@invalidRequest="handleInvalidRequest"
									@saved="handleSavedStep('RenewalPayment')"
									@changePaymentMethodSelected="changePaymentMethodSelected" />
							</flow-info-section>
							<flow-info-section
								v-if="cartHasTickets"
								:compact="false"
								init-edit
								hide-controls
								:title="translate('event_attendees')"
								active-color="dark"
								hide-cancel
								override-edit
								:no-inner-spacing="true">
								<div
									v-if="!systemLoading">
									<!-- <div
										class="col-12 mt-3">
										<p class="my-0 font-weight-bolder text-danger">
											{{ translate('ticket_not_refundable_disclaimer') }}
										</p>
									</div> -->
									<event-ticket
										v-for="(item, key) in tickets"
										:key="key"
										:ticket-code-name="ticketCodeName(item.code_name)"
										:names="eventAttendees[ticketCodeName(item.code_name)]"
										:quantity="item.qty"
										:ticket-count="item.tickets_count"
										:total-tickets="item.total_tickets"
										:companion-types="item.companion_types"
										:bed-types="item.bed_types"
										:bed-types-visibility="item.room_types_visibility"
										:check-in-dates="item.check_in_dates"
										:reservation-nights="item.reservation_nights"
										@dataChanged="saveTickets(ticketCodeName(item.code_name), $event)" />
								</div>
								<div
									v-else
									class="col-12 fade-in text-center d-flex justify-content-center align-items-center mt-3 mb-3">
									<div class="col-12">
										<div class="h2">
											<i class="fa fa-fw fa-spinner fa-pulse" />
											<h4 class="mt-3">
												{{ translate('loading') }}
											</h4>
										</div>
									</div>
								</div>
							</flow-info-section>
						</flow-info-group>
					</div>
					<div class="col-12">
						<div class="row mt-4 pl-4">
							<div class="col">
								<h5>{{ translate('review_your_cart_items') }}</h5>
							</div>
						</div>
						<div
							v-if="showMessages"
							class="row mt-1">
							<div class="col">
								<possible-discounts
									v-if="!systemLoading"
									:possible-discount-messages="possibleDiscountMessages"
									:free-products-info="freeProductsInfo"
									:promotions="promotions" />
							</div>
						</div>
						<div class="row mt-1">
							<div
								class="col">
								<cart-items
									:loading="systemLoading || loadingInitialInformation"
									:cart-items="cartProducts"
									:qty-import="qty"
									:editing-disabled="true"
									hide-loading
									:is-pack="false"
									:package-option="cartOptions.package_option"
									@update-quantity="updateQuantity"
									@remove-product="removeProduct"
									@use-points="usePoints"
									@remove-points="removePoints"
									@update-split-wallet="updateSplitWallet" />
							</div>
						</div>
						<is-loading
							:loading-label="translate('loading')"
							:no-data-label="translate('empty_cart')"
							:loading="systemLoading || loadingInitialInformation"
							:has-data="cartProducts.length > 0" />
						<hr class="my-3 w-100">
					</div>
				</div>
			</div>
			<div
				:class="{ 'mt-3': ['xs', 'sm'].includes(windowWidth) }"
				class="col-12 col-md-4">
				<order-summary
					:cart-id="cartId"
					:cart-loading="systemLoading || loadingInitialInformation"
					:cart-totals="cartTotals"
					:cart-points="cartPoints"
					:cart-small-text-totals="cartSmallTextTotals"
					:cart-items="cartProducts"
					:cart-total="cartTotal"
					:has-tickets="cartHasTickets"
					:tickets-info="eventAttendees"
					:total-volume="totalVolume"
					:split-payment-info="splitPaymentInfo"
					:split-payment-loading="splitPaymentLoading"
					:clicked-payment-method="clickedPayment"
					:exchange="exchange.is_needed ? exchange.total : ''"
					:terms-and-policies="termsAndPolicies"
					:credits-used="creditsUsed"
					:apply-free-bottle="applyFreeBottle"
					:free-bottle-message="freeBottleMessage"
					:editing-other-steps="isEditingAnyStep"
					:skip-steps="cartHasTickets && hideAddresses"
					:upgrade-packages="upgradePackages"
					:discount-detail="discountDetail"
					:membership="membership"
					:style="['xs', 'sm'].includes(windowWidth) ? '' : `top: 85px !important;`"
					:class="['xs', 'sm'].includes(windowWidth) ? '' : 'sticky-top'"
					@use-points="usePoints"
					@remove-points="removePoints"
					@cartValidationError="redirectToStore"
					@invalidRequest="handleInvalidRequest"
					@serverError="redirectToStore"
					@purchaseClick="handleOrderClick"
					@purchaseErrors="handlePurchaseErrors"
					@cartReplaced="handleCartReplaced"
					@splitPayment="splitPayment"
					@reloadCart="getCartTotals" />
			</div>
		</div>
		<b-alert
			v-if="hasPurchaseErrors && ['xs','sm'].includes(windowWidth)"
			class="mt-2"
			show
			variant="danger">
			<h5 class="font-weight-bold">
				<i class="fa fa-exclamation-triangle" /> {{ translate('verify_following_errors') }}:
			</h5>
			<ul class="h6">
				<template v-for="(error, index) in purchaseErrors">
					<li :key="index">
						{{ error }}
					</li>
				</template>
			</ul>
		</b-alert>
	</div>
</template>
<script>
import CartItems from '@/components/Cart/CartItems';
import EventTicket from '@/components/EventTicket';
import FlowInfoGroup from '@/components/FlowInfo/Group';
import FlowInfoSection from '@/components/FlowInfo/Section';
import IsLoading from '@/components/Loading';
import CartData from '@/mixins/CartData';
import PaymentMethods from '@/mixins/PaymentMethods';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import { EXPIRATION_VALUE, EXPIRATION_TIME } from '@/settings/Cookie';
import { distributor } from '@/settings/Roles';
import { COUNTRIES_SPLIT_WITH_WALLET_BLACKLIST, PRODUCTS_NOT_ALLOWED_WITH_WALLET } from '@/settings/Purchase';
import {
	Cart, Grids, Profile, Purchase as PurchaseMessages, Validations, Events,
} from '@/translations';
import Purchase from '@/util/Purchase';
import PossibleDiscounts from '@/components/PossibleDiscounts';
import OrderSummary from './components/Confirmation/OrderSummary';
import PaymentOverview from './components/Payment/Overview';
import PaymentEdit from './components/Payment/Edit';
import Steps from './mixins/Steps';
import store from './store';

export default {
	name: 'RenewalConfirmation',
	messages: [Cart, Grids, Profile, PurchaseMessages, Validations, Events],
	components: {
		PossibleDiscounts,
		CartItems,
		FlowInfoGroup,
		FlowInfoSection,
		IsLoading,
		OrderSummary,
		PaymentOverview,
		PaymentEdit,
		EventTicket,
	},
	mixins: [CartData, PaymentMethods, DocumentTitle, Steps, WindowSizes],
	data() {
		return {
			purchaseInfo: new Purchase(),
			distributor,
			canLeave: false,
			editing: {
				RenewalPayment: false,
				RenewalConfirmation: false,
			},
			purchaseErrors: [],
			clickedPayment: '',
			walletSplit: false,
			productsNotAllowedWithWallet: PRODUCTS_NOT_ALLOWED_WITH_WALLET,
			countriesNotSplitWithWallet: COUNTRIES_SPLIT_WITH_WALLET_BLACKLIST,
			isSplitPayment: false,
			membership: 'backoffice',
		};
	},
	computed: {
		canSplitWithWallet() {
			return false;
			// return this.$user.auth() && [distributor, affiliate].includes(this.$user.details().type)
			// 	&& this.cartProducts.filter((item) => this.productsNotAllowedWithWallet.includes(item.sku)).length === 0
			// && !this.countriesNotSplitWithWallet.includes(this.$user.details().country.iso_code_2);
		},
		loadingInitialInformation() {
			return this.purchaseInfo.data.loading;
		},
		isEditingAnyStep() {
			if (this.hideAddresses) {
				return false;
			}

			return Object.keys(this.editing)
				.filter((stepName) => stepName !== 'RenewalConfirmation')
				.some((stepName) => this.editing[stepName]);
		},
		hasPurchaseErrors() {
			return !!this.purchaseErrors.length;
		},
		paymentMethodSelected() {
			const { payment } = { ...store.getters.getStepInformation('RenewalPayment') };
			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			return payment?.payment_method?.name || this.clickedPayment;
		},
	},
	watch: {
		// cartProducts(newVal) {
		// 	if (!newVal.length) {
		// 		this.alert.toast('error', this.translate('empty_cart'), { timer: 6000 });
		// 		setTimeout(() => {
		// 			this.redirectToStore();
		// 		}, 6000);
		// 	}
		// },
		skipPaymentMinValidation(newVal) {
			this.skipMinValidation = newVal;
		},
		paymentMethodConditions: {
			deep: true,
			handler() {
				if (typeof this.paymentMethodConditions[this.paymentMethodSelected] !== 'undefined' && !this.paymentMethodConditions[this.paymentMethodSelected]?.isAvailable) {
					this.resetPaymentMethod();
				}
			},
		},
		clickedPayment(newVal) {
			if (typeof this.paymentMethodConditions[newVal] !== 'undefined' && !this.paymentMethodConditions[newVal]?.isAvailable) {
				this.editStep('RenewalPayment');
			}
		},
		cartNumericTotal(total) {
			this.setAmountToPay(total);
		},
		walletSplit(newVal) {
			if (newVal) {
				this.$refs.paymentEdit.updateSplitPaymentValue(false);
			}
		},
	},
	async mounted() {
		this.membership = this.$route.query.membership ?? 'backoffice';
		this.setCartFlow('renewal');
		this.setFlowName('renewal');
		await store.dispatch('removeInfo');
		await this.getStartingInfo();
		if (typeof this.paymentMethodConditions[this.paymentMethodSelected] !== 'undefined' && !this.paymentMethodConditions[this.paymentMethodSelected]?.isAvailable) {
			await this.resetPaymentMethod();
		}
		if (this.$user.details().type === this.distributor) {
			this.getUpgradePackagesInfo();
		}
	},
	methods: {
		async getStartingInfo() {
			const response = await this.purchaseInfo.getRenewalInformation(this.$user.details().id, this.membership);
			if (this.$route.params.storedCartId) {
				response.response.cart_id = this.$route.params.storedCartId;
			}
			if (this.$route.params.package_option) {
				this.cartOptions.package_option = this.$route.params.package_option;
			}
			await store.dispatch('saveInitialInformation', response.response);
			return this.setCartCookie(response.response.cart_id, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME}` });
		},
		completedSteps() {
			return store.getters.getCompletedSteps();
		},
		stepIsCompleted(step) {
			return this.completedSteps().includes(step);
		},
		isEditing(step) {
			return this.editing[step];
		},
		editStep(step) {
			this.editing[step] = true;
		},
		setStepAsNotEditing(step) {
			this.editing[step] = false;
		},
		isCompact(step) {
			return !this.isEditing(step) && this.stepIsCompleted(step);
		},
		handleCancelledStep(step) {
			this.setStepAsNotEditing(step);
		},
		handleSavedStep(step) {
			this.getCartTotals();
			this.setStepAsNotEditing(step);
		},
		changePaymentMethodSelected(selected) {
			if (selected !== 0) this.clickedPayment = selected;
		},
		handleInvalidRequest() {
			// this.createNewCart();
			// this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
			// setTimeout(() => {
			this.resetStepsAndRedirect();
			// }, 6000);
		},
		handlePurchaseErrors(purchaseErrors) {
			this.purchaseErrors = purchaseErrors;
		},
		handleOrderClick() {
			this.purchaseErrors = [];
		},
		handleCartReplaced(cartId, reloadCart = true) {
			this.setCartCookie(cartId, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME.minutes}` }, reloadCart);
		},
		resetStepsAndRedirect() {
			store.dispatch('removeInfo').then(() => {
				this.redirectToStore();
			}).catch(() => {});
		},
		redirectToStore() {
			this.canLeave = true;
			this.$router.replace({ name: 'Store' });
		},
		resetPaymentMethod() {
			const paymentInformation = store.getters.getStepInformation('RenewalPayment');
			const data = {
				RenewalPayment: {
					payment: {
						billing: {
							address_id: 0,
						},
					},
				},
			};
			if (paymentInformation.payment) {
				data.RenewalPayment.payment.billing.address_id = paymentInformation.payment.billing.address_id;
			}
			return store.dispatch('saveInfo', data)
				.then(() => store.dispatch('saveStepAsIncomplete', 'RenewalPayment'))
				.then(() => this.editStep('RenewalPayment'));
		},
		ticketCodeName(codeName) {
			if (codeName.endsWith('_mp')) {
				return codeName.slice(0, -3);
			}
			return codeName;
		},
		enableWalletSplitAndEdit() {
			this.walletSplit = true;
			this.editStep('RenewalPayment');
		},
		updateSplitWallet() {
			if (this.walletSplit || this.isSplitPayment) {
				this.editStep('RenewalPayment');
			}
		},
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('Renewal')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (to.name.includes('Store')) {
			if (this.canLeave) {
				next();
				const alert = new this.$Alert();
				alert.close();
			} else {
				next();
			}
		}
	},
};
</script>
