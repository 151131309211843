import Vue from 'vue';
import Vuex from 'vuex';
import { RENEWAL_STEPS as flowSteps } from '@/settings/Wizard';

const steps = [];
Object.keys(flowSteps).forEach((stepName) => {
	if (flowSteps[stepName].innerSteps) {
		steps.push(...flowSteps[stepName].innerSteps);
	}
	steps.push({ [stepName]: flowSteps[stepName] });
});

const options = {};
steps.forEach((step) => {
	options[Object.keys(step)[0]] = {};
});

Vue.use(Vuex);

const Store = new Vuex.Store({
	strict: process.env.VUE_APP_ENV !== 'production',
	state: {
		initialInformation: {},
		currentStep: 0,
		completedSteps: [],
		...options,
	},
	getters: {
		getStepInformation(state) {
			return (payload) => {
				if (Object.keys(state).includes(payload)) {
					return state[payload];
				}
				return null;
			};
		},
		getCurrentStep(state) {
			return () => state.currentStep;
		},
		getCompletedSteps(state) {
			return () => state.completedSteps;
		},
		getInitialInformation(state) {
			return () => state.initialInformation;
		},
	},
	mutations: {
		setState(state, payload) {
			const [name] = Object.keys(payload);
			state[name] = payload[name];
		},
	},
	actions: {
		saveInfo(context, payload) {
			const { commit, state } = context;
			const [name] = Object.keys(payload);
			if (Object.keys(options).includes(name)) {
				commit('setState', { completedSteps: [...state.completedSteps, name] });
				commit('setState', payload);
				return Promise.resolve(true);
			}
			return Promise.reject(new Error('invalid_step'));
		},
		saveInitialInformation({ commit }, payload) {
			commit('setState', { initialInformation: payload });
		},
		saveStepNumber({ commit }, payload) {
			try {
				commit('setState', { currentStep: payload });
				return Promise.resolve(true);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		saveStepAsIncomplete(context, incompleteStep) {
			const { commit, state } = context;
			commit('setState', { completedSteps: [...state.completedSteps.filter((stepName) => stepName !== incompleteStep)] });
			return Promise.resolve(true);
		},
		removeInfo({ commit }) {
			Object.keys(options).forEach((item) => {
				const payload = {};
				payload[item] = {};
				commit('setState', payload);
			});
			commit('setState', { completedSteps: [] });
			commit('setState', { currentStep: 0 });
		},
		removeCart({ commit }) {
			commit('setState', { cartId: '' });
		},
		saveCart({ commit }, payload) {
			try {
				commit('setState', { cartId: payload });
				return Promise.resolve(true);
			} catch (error) {
				return Promise.reject(error);
			}
		},
	},
});

export default Store;
